<section class="contact">
    <p i18n class="success-msg" *ngIf="successMsg">Your message has been successfully sent</p>
    <p i18n class="error-msg" *ngIf="errorMsg">Your message can not be sent at the moment</p>
    <p i18n class="heading-title">Contact</p>
    <form (ngSubmit)="onSubmit()"  [formGroup]="contactForm">
        <span i18n>Name:</span>
        <input
                type="text"
                name="name"
                formControlName="name"><br>

        <span i18n>Email:</span>
        <input
                type="email"
                name="email"
                formControlName="email"><br>

        <span i18n>Subject:</span>
        <input
                type="text"
                name="subject"
                formControlName="subject"><br>

        <span i18n>Message:</span>
        <textarea
                name="message"
                formControlName="message"
                rows="3" cols="20"></textarea>

        <input type="submit" i18n-value value="Send" [disabled]="!contactForm.valid">
    </form>
</section>