import {Component, OnInit} from '@angular/core';
import {WebsiteService} from '../website.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
    gallery: Array<string> = [];
    currentIndex = 0;
    status = 'active';
    image;

    constructor(private websiteService: WebsiteService) {
    }

    ngOnInit() {
        this.image = document.getElementById('carousel-picture');
        this.websiteService.getWebsite().subscribe(
            (data: Object) => {
                const photos = data['slideshow'];
                this.gallery = photos.map((el) => {
                    return environment.resourcesUrl + '/photo/s/' + el;
                });
                this.setPicture(this.gallery[this.currentIndex]);
                this.preloadImages();
                setInterval(() => {
                     this.changeState();
                }, 5000);
            }, // success path
            // error => this.error = error // error path
        );
    }

    setPicture(src) {
        this.image.src = src;
        // this.image.srcset = src + ' 1500w' + ', ' + src.replace('photo/s/', '/photo/m/') + ' 500w';
    }

    activate() {
        this.image.classList.remove('carousel-inactive');
        this.image.classList.add('carousel-active');
    }

    deactivate() {
        this.image.classList.remove('carousel-active');
        this.image.classList.add('carousel-inactive');
    }

    async changeState() {
        this.deactivate();
        await new Promise((resolve) => {
            setTimeout(function () {
                resolve();
            }, 800);
        });
        this.currentIndex++;
        if (this.currentIndex === this.gallery.length) {
            this.currentIndex = 0;
        }
        this.setPicture(this.gallery[this.currentIndex]);
        await new Promise((resolve) => {
            setTimeout(function () {
                resolve();
            }, 100);
        });
        this.activate();
    }

    async preloadImages() {
        const images = [];
        for (let i = 0; i < this.gallery.length; i++) {
            images[i] = new Image();
            images[i].src = this.gallery[i];
            // images[i].srcset = this.gallery[i] + '1500w' + ', ' + this.gallery[i].replace('photo/s/', '/photo/m/') + '500w';
        }
    }

    onRightClick() {
        return false;
    }
}
