import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {throwError, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class WebsiteService {
    data;
    observable;

    constructor(private http: HttpClient) {
        this.http = http;
    }

    getWebsite() {
        if (this.data) {
            return of(this.data);
        } else {
            this.observable = this.http.get(environment.resourcesUrl + '/data/website', {
                observe: 'response'
            }).pipe(map(response => {
                this.observable = null;
                if (response.status === 400) {
                    return 'Request failed.';
                } else if (response.status === 200) {
                    this.data = response.body;
                    return this.data;
                }
            }));
            return this.observable;
        }
    }

    sendMessage(message) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post(environment.resourcesUrl + '/data/send', message, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}
