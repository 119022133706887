import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {WebsiteService} from '../website.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-biography',
    templateUrl: './biography.component.html',
    styleUrls: ['./biography.component.css']
})
export class BiographyComponent implements OnInit {
    photo;
    textList;

    constructor(private websiteService: WebsiteService, @Inject(LOCALE_ID) private language: string) {
        if (this.language === 'en-US') {
            this.language = 'en';
        }
    }

    ngOnInit() {
        this.websiteService.getWebsite().subscribe(
            (data: Object) => {
                const bio = data['bio'];
                this.photo = environment.resourcesUrl + '/photo/l/' + bio['photo'];
                if (bio['name_' + this.language] !== undefined) {
                    const rawText: string = bio['name_' + this.language];
                    this.textList = rawText.split('\r\n');
                }

            }, // success path
            // error => this.error = error // error path
        );
    }

}
