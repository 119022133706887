<footer class="main-footer">
    <div class="copyright"><span class="copy-mark">&copy;</span> <ng-container i18n>Ivan Pastoukhov</ng-container></div>
    <div class="social">
        <a href="https://www.facebook.com/pastoukhov" target="_blank">
            <fa-icon [icon]="faFacebook" class="fa-facebook"></fa-icon>
        </a>
        <a href="http://www.youtube.com/channel/UCtcPrnv-lyVWuBnL7e5Rfeg" target="_blank">
            <fa-icon [icon]="faYoutube" class="fa-youtube"></fa-icon>
        </a>
        <a href="{{blogUrl}}" target="_blank">
            <fa-icon [icon]="faWordpress" class="fa-wordpress"></fa-icon>
        </a>
    </div>
    <div class="languages">
        <a href="/en"><img src="/assets/flags/gb.svg" class="flag"></a>
        <a href="/fr"><img src="/assets/flags/fr.svg" class="flag"></a>
        <a href="/bg"><img src="/assets/flags/bg.svg" class="flag"></a>
    </div>
</footer>