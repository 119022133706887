import {Component, OnInit, LOCALE_ID, Inject} from '@angular/core';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import {ActivatedRoute} from '@angular/router';
import {WebsiteService} from '../website.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
    photos = [];
    galleryName = '';
    galleryId = '';
    resourceUrl: String = environment.resourcesUrl;

    constructor(private route: ActivatedRoute, private websiteService: WebsiteService, @Inject(LOCALE_ID) private language: string) {
        if (this.language === 'en-US') {
            this.language = 'en';
        }
    }

    ngOnInit() {
        this.galleryId = this.route.snapshot.paramMap.get('galleryId') + '.jpg';
        this.websiteService.getWebsite().subscribe((data: Object) => {
            const photos = data['galleries'][this.galleryId]['photos'];

            photos.forEach((item, index, array) => {
                if (item.src.startsWith('http') === false) {
                    item.src = this.resourceUrl + item.src;
                    item.msrc = this.resourceUrl + item.msrc;
                    array[index] = item;
                }
            });
            this.photos = photos;
            this.galleryName = data['galleries'][this.galleryId]['name_' + this.language];
        });
    }

    openGallery(photoIndex = 0) {
        const pswpElement = document.querySelectorAll('.pswp')[0];
        const items = this.photos;
        const options = {
            index: photoIndex,
            closeEl: true,
            captionEl: true,
            fullscreenEl: true,
            zoomEl: true,
            shareEl: false,
            counterEl: true,
            arrowEl: true,
            preloaderEl: true,
        };

        // Initializes and opens PhotoSwipe
        const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    }

    onRightClick() {
        return false;
    }
}
