import {Component, OnInit, LOCALE_ID, Inject} from '@angular/core';
import {faFacebook, faYoutube, faWordpress} from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    faFacebook = faFacebook;
    faYoutube = faYoutube;
    faWordpress = faWordpress;
    blogUrl;

    constructor(@Inject(LOCALE_ID) private language: string) {
        if (language === 'bg') {
            this.blogUrl = 'http://www.bg.pastoukhov.com';
        } else {
            this.blogUrl = 'http://www.fr.pastoukhov.com';
        }
    }

    ngOnInit() {
    }
}
