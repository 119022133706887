import {Component, OnInit, LOCALE_ID, Inject} from '@angular/core';
import {WebsiteService} from '../website.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
    objectKeys = Object.keys;
    list: Object = {};
    resourceUrl: String = environment.resourcesUrl;

    constructor(@Inject(LOCALE_ID) private language: string, private websiteService: WebsiteService) {
        if (this.language === 'en-US') {
            this.language = 'en';
        }
    }

    ngOnInit() {
        this.websiteService.getWebsite().subscribe((data: Object) => {
            this.list = data['galleries'];
        });
    }

    getGalleryIdByKey(key) {
        return key.replace(/\.[^/.]+$/, ''); // remove the .jpg extension
    }

    onRightClick() {
        return false;
    }
}
