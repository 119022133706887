import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    ngOnInit() {
    }

    constructor(private titleService: Title, @Inject(LOCALE_ID) private language: string) {
        if (this.language === 'en-US') {
            this.language = 'en';
        }

        let title;
        switch (this.language) {
            case 'bg':
                title = 'Иван Пастухов - Фотография';
                break;
            case 'fr':
                title = 'Ivan Pastoukhov - Photographie';
                break;
            default:
                title = 'Ivan Pastoukhov - Photography';
        }
        this.setTitle(title);
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}
