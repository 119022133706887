<header class="main-header">
    <div class="site-title">
        <a class="site-author" routerLink="/" i18n>Ivan Pastoukhov</a>
        <p class="site-subtitle" i18n>PHOTOGRAPHY</p>
    </div>
    <div class="navbar-toggle" (click)="toggleSidebar()">
        <fa-icon [icon]="faBars" class="fa-bars"></fa-icon>
    </div>
    <nav class="navbar" id="main-navbar" [ngClass]="isSidebarOpen ? 'navbar-open' : '' ">
        <a routerLink="/" (click)="toggleSidebar()" i18n>HOME</a>
        <a routerLink="/list" (click)="toggleSidebar()" i18n>GALLERY</a>
        <a routerLink="/biography" (click)="toggleSidebar()" i18n>BIOGRAPHY</a>
        <a routerLink="/contact" (click)="toggleSidebar()" i18n>CONTACT</a>
    </nav>
</header>