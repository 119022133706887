<section class="list-page">
    <div class="gallery-list">
        <figure class="gallery-thumb" *ngFor="let key of objectKeys(list)">
            <div class="thumb-wrapper">
                <a routerLink="{{'/gallery/' + getGalleryIdByKey(key) }}">
                    <img src="{{resourceUrl + list[key]['thumbnail']}}" alt="{{list[key]['name_' + language]}}" (contextmenu)="onRightClick($event)"/>
                </a>
                <figcaption class="gallery-thumb-caption">{{list[key]['name_' + language]}}</figcaption>
            </div>
        </figure>

    </div>
</section>