import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {AppComponent} from './app.component';
import {CarouselComponent} from './carousel/carousel.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ListComponent} from './list/list.component';
import {GalleryComponent} from './gallery/gallery.component';
import {RouterModule} from '@angular/router';
import {ContactComponent} from './contact/contact.component';
import {BiographyComponent} from './biography/biography.component';
import {WebsiteService} from './website.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

const routes = [
    {path: '', component: CarouselComponent},
    {path: 'list', component: ListComponent},
    {path: 'gallery/:galleryId', component: GalleryComponent},
    {path: 'biography', component: BiographyComponent},
    {path: 'contact', component: ContactComponent},
    {path: '**', redirectTo: ''}
];

@NgModule({
    declarations: [
        AppComponent,
        CarouselComponent,
        HeaderComponent,
        FooterComponent,
        ListComponent,
        GalleryComponent,
        ContactComponent,
        BiographyComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        FontAwesomeModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    providers: [WebsiteService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
