import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {WebsiteService} from '../website.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

    contactForm: FormGroup;
    successMsg = false;
    errorMsg = false;

    constructor(private websiteService: WebsiteService) {
        this.contactForm = new FormGroup({
            'name': new FormControl('', [Validators.required]),
            'email': new FormControl('', [Validators.required, Validators.email]),
            'subject': new FormControl('', [Validators.required]),
            'message': new FormControl('', [Validators.required]),
        });
    }

    ngOnInit() {
    }

    onSubmit() {
        this.websiteService.sendMessage(this.contactForm.value).subscribe(
            () => {
                this.successMsg = true;
                this.errorMsg = false;
                this.contactForm.reset();
            }, // success path
            () => {
                this.successMsg = false;
                this.errorMsg = true;
                this.contactForm.reset();
            }
            // error => this.error = error // error path
        );
    }
}
